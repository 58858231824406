<template>
  <div v-if="!mini" class="audio-player">
    <audio controls controlslist="nodownload">
      <source
        :src="link"
      />
    </audio>
  </div>
  <div class="audio-payer-mini" v-else>
    <audio id="audio" :src="link"></audio>
    <font-awesome-icon v-if="!isPlaying" icon="play" class="icon" @click="handleAudio"/>
    <font-awesome-icon v-else icon="pause" class="icon" @click="handleAudio"/>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

library.add(faPlay, faPause);

export default {
  name: 'AudioPlayer',
  props: {
    link: {
        type: String,
        default: ''
    },
    mini: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPlaying: false
    }
  },
  methods: {
    handleAudio() {
      const audio = document.getElementById("audio");
      this.isPlaying ? audio.pause() : audio.play();
      this.isPlaying = !this.isPlaying;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.audio-player {
  height: 54px;
  width: 100%;
  border-radius: 4px;
  background: hsl(0, 0%, 100%);
  box-shadow: 1px 1px 10px 0px #b6b6b6;

  audio {
    width: 100%;
  }

  audio::-webkit-media-controls-panel {
    background-color: hsl(0, 0%, 100%);
  }
}

.icon {
  color: $purple;
}
</style>
