<template>
  <div class="view content-padding media-content">
    <SvgLoader :loading="isLoading" />
    <div v-if="!isLoading" class="row">
      <div class="col-lg-9 col-12">
        <BaseCard
          v-if="mediaType !== 'audio'"
          margin="0 0 30px"
          class="preview-base-card"
        >
          <div class="entity__preview preview">
            <img
              class="preview__img"
              v-if="mediaType === 'image'"
              :src="singleData.media"
              :alt="singleData.alt"
            />
            <video
              class="preview__video"
              v-else-if="mediaType === 'video'"
              controls
            >
              <source :src="singleData.media" />
              {{ this.labels.browser_does_not_support_video }}
            </video>
          </div>
        </BaseCard>

        <AudioPlayer
          class="preview__audio"
          v-if="mediaType === 'audio'"
          :link="singleData.media"
        />

        <div v-if="mediaType === 'audio'">
          <LabelInput
            class="mb-4"
            font-family="'Oswald', sans-serif"
            :base-card="true"
            :label="labels.title"
            :value="singleData.title"
            @changed="dataChanged({ value: $event, key: 'title' })"
          />
          <LabelInput
            class="mb-4"
            font-family="'Oswald', sans-serif"
            :base-card="true"
            :label="labels.description"
            :value="singleData.text"
            type="textarea"
            @changed="dataChanged({ value: $event, key: 'text' })"
          />
        </div>

        <BaseCard padding="25px" margin="0 0 30px">
          <div class="entity__upload">
            <div class="upload-url">
              <FormulateInput
                type="url"
                name="URL"
                :label="labels.media_url"
                validation="required"
                class="customize-label w-100"
                :disabled="true"
                :placeholder="mediaValue"
                :class="{ active: mediaValue }"
                @focus="onFocus"
                @blur="onBlur"
              />
            </div>
            <div class="upload-btn ml-lg-5 ml-sm-3 ml-2">
              <input
                type="file"
                name="file"
                id="file"
                :accept="allowedExtensions"
                @change="onFileChange"
              />
              <label for="file" class="btn button btn-primary">{{
                labels.upload
              }}</label>
            </div>
          </div>
        </BaseCard>
      </div>
      <div class="col-lg-3 col-12">
        <div v-if="mediaType !== 'audio'">
          <LabelInput
            class="mb-4"
            font-family="'Oswald', sans-serif"
            :base-card="true"
            :label="labels.title"
            :value="singleData.title"
            @changed="dataChanged({ value: $event, key: 'title' })"
          />
          <LabelInput
            class="mb-4"
            font-family="'Oswald', sans-serif"
            :base-card="true"
            :label="labels.description"
            :value="singleData.text"
            type="textarea"
            @changed="dataChanged({ value: $event, key: 'text' })"
          />
        </div>
        <BaseCard padding="25px" margin="0 0 30px">
          <div class="info-buttons">
            <Button
              :label="labels.copy_link"
              :action="embedLink"
              :bg-color="`${colorsHtml.purple}`"
              class="copy-link"
            />
            <Button
              :label="labels.delete"
              class="info-button"
              bg-color="#dc3545"
            />
          </div>
        </BaseCard>
        <LabelInput
          class="mb-4"
          font-family="'Oswald', sans-serif"
          :base-card="true"
          :label="labels.alt_text"
          :value="singleData.alt"
          @changed="dataChanged({ value: $event, key: 'alt' })"
        />
        <LabelButtonCard class="settings__cards" :list="labelButtonList" />
      </div>
    </div>
  </div>
</template>

<script>
//const moment = require('moment');

import BaseCard from "@/components/Cards/BaseCard.vue";
import AudioPlayer from "@/components/Media/AudioPlayer";
import LabelButtonCard from "@/components/Cards/LabelButtonCard";
import LabelInput from "@/components/Input/LabelInput";
import SvgLoader from "@/components/Preloaders/SvgLoader";
import Button from "@/components/Buttons/Button";
import colorsHtml from "/colors.config.json";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "ContractContent",
  components: {
    BaseCard,
    AudioPlayer,
    LabelButtonCard,
    LabelInput,
    SvgLoader,
    Button,
  },
  data() {
    return {
      colorsHtml: colorsHtml,
      value: "",
      fileUrl: "",
      selectedFile: "",
    };
  },
  mounted() {},
  methods: {
    ...mapActions("notification", ["addNotification"]),
    ...mapMutations("media", ["updateStateSingleMediaByKey"]),
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const year = date.getFullYear();

      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }

      if (month < 10) {
        month = "0" + month;
      }

      return `${year}.${month}.${dt}`;
    },
    dataChanged(data) {
      this.updateStateSingleMediaByKey(data);
    },
    onFileChange(e) {
      if (e?.target?.files?.length) {
        this.selectedFile = e.target.files[0];
        this.fileUrl = URL.createObjectURL(this.selectedFile);

        this.updateStateSingleMediaByKey({
          key: "media",
          value: {
            old: this.stateSingleData.media,
            fileToUpload: this.selectedFile,
          },
        });
      }
    },
    onFocus(e) {
      e.target.closest(".customize-label").classList.add("active");
    },
    onBlur(e) {
      if (!e.target.value) {
        e.target.closest(".customize-label").classList.remove("active");
      }
    },
    embedLink() {
      navigator.clipboard.writeText(
        `${window.location.origin}${this.singleData.media}`
      );
      this.addNotification({
        variant: "success",
        msg: this.labels.link_copied,
      });
    },
  },
  computed: {
    ...mapState("media", ["singleData", "stateSingleData"]),
    ...mapState("helper", ["allowedMediaTypes"]),
    ...mapGetters("helper", ["labels"]),
    labelButtonList() {
      if (!this.singleData?.lastChanged || !this.singleData?.created) return [];

      return [
        {
          labels: [
            this.labels.last_change,
            this.formatDate(this.singleData.lastChanged.date),
          ],
        },
        {
          labels: [
            this.labels.created,
            this.formatDate(this.singleData.created.date),
          ],
        },
        {
          labels: [
            this.labels.last_change_by,
            this.singleData.lastChanged.author.username,
          ],
        },
        {
          labels: [
            this.labels.created_by,
            this.singleData.created.author.username,
          ],
        },
      ];
    },
    isLoading() {
      if (!this.singleData?._id) return true;

      return false;
    },
    mediaType() {
      if (this.singleData?.mediaType) return this.singleData.mediaType;

      return "";
    },
    mediaValue() {
      if (this.fileUrl?.length) {
        return this.fileUrl;
      } else if (this.singleData?.media) {
        return this.singleData?.media;
      }

      return "";
    },
    allowedExtensions() {
      let result = "";

      if (Object.keys(this.allowedMediaTypes).length) {
        Object.entries(this.allowedMediaTypes).forEach((entry) => {
          const [mimeType, extension] = entry;

          if (mimeType.includes(this.singleData.mediaType))
            result += `${extension},`;
        });
      }

      return result;
    },
  },
  watch: {
    singleData() {
      this.selectedFile = "";
      this.fileUrl = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.media-content {
  video {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  ::v-deep {
    .preview__audio {
      margin-bottom: 30px;
      box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .entity__upload {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .upload-url {
      flex: 1 1 auto;
      ::v-deep .formulate-input {
        height: 100%;
        .formulate-input-wrapper {
          height: 100%;
          .formulate-input-element {
            height: 100%;
            max-width: unset;

            input {
              height: 100%;
            }
          }
        }
      }
    }

    .upload-btn {
      position: relative;
      margin-top: auto;

      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      label {
        border: 1px solid $navy;
        background-color: $navy;
        color: $white;
        padding: 0.72rem 1.6rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: 1px;
        margin-bottom: 0;

        &:hover {
          background-color: $navy !important;
          border-color: $navy !important;
          color: $white;
        }
      }
    }
  }

  .info-buttons {
    display: flex;
    justify-content: flex-end;
    flex-direction: column !important;
  }
  .copy-link {
    border: 2px solid $purple;
    color: $white;

    &:hover {
      background: $white;
      color: $purple;
      filter: none;
    }
  }
  .info-button {
    margin-top: 10px;
    color: $white;
    border: 2px solid #dc3545;

    &:hover {
      background: $white;
      color: #dc3545;
      filter: none;
    }
  }
}
::v-deep .settings__cards {
  padding: 0;

  &.label-button-card {
    padding: 0;
    margin-top: 30px;
  }
}

::v-deep .formulate-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline !important;

  label {
    margin-right: 0px !important;
    margin-bottom: 5px;
  }

  .formulate-input-element {
    width: 100%;
  }
}
</style>
